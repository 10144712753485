<script setup>
import { Link, router, useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';

import ActionMessage from '@/Components/Section/ActionMessage.vue';
import Button from '@/Components/Section/Button.vue';
import { objectMap } from '@/utilities';

let route = inject('route');

const props = defineProps({
    user: Object,
});

const form = useForm({
    _method: 'PUT',
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    email: props.user.email,
    locale: props.user.locale,
    photo: null,
});

const verificationLinkSent = ref(null);
const photoPreview = ref(null);
const photoInput = ref(null);

const updateProfileInformation = () => {
    if (photoInput.value) {
        form.photo = photoInput.value.files[0];
    }

    form.post(route('user-profile-information.update'), {
        errorBag: 'updateProfileInformation',
        preserveScroll: true,
        onSuccess: () => clearPhotoFileInput(),
        onError: (errors) => (form.errors = objectMap(errors, (error) => [error])),
    });
};

const sendEmailVerification = () => {
    verificationLinkSent.value = true;
};

const selectNewPhoto = () => {
    photoInput.value.click();
};

const updatePhotoPreview = () => {
    const photo = photoInput.value.files[0];

    if (!photo) return;

    const reader = new FileReader();

    reader.onload = (e) => {
        photoPreview.value = e.target.result;
    };

    reader.readAsDataURL(photo);
};

const deletePhoto = () => {
    router.delete(route('current-user-photo.destroy'), {
        preserveScroll: true,
        onSuccess: () => {
            photoPreview.value = null;
            clearPhotoFileInput();
        },
    });
};

const clearPhotoFileInput = () => {
    if (photoInput.value?.value) {
        photoInput.value.value = null;
    }
};
</script>

<template>
    <Section>
        <template #title>{{ $t('Profile information') }}</template>

        <template #description>
            {{ $t("Update your account's profile information and email address.") }}
        </template>

        <template #form>
            <!-- Profile Photo -->
            <div v-if="$page.props.jetstream.managesProfilePhotos">
                <!-- Profile Photo File Input -->
                <input ref="photoInput" type="file" class="hidden" @change="updatePhotoPreview" />

                <JetLabel for="photo" value="Photo" />

                <!-- Current Profile Photo -->
                <div v-show="!photoPreview" class="mt-2">
                    <img :src="user.profile_photo_url" :alt="user.name" class="object-cover w-20 h-20 rounded-full" />
                </div>

                <!-- New Profile Photo Preview -->
                <div v-show="photoPreview" class="mt-2">
                    <span
                        class="block w-20 h-20 bg-center bg-no-repeat bg-cover rounded-full"
                        :style="'background-image: url(\'' + photoPreview + '\');'"
                    />
                </div>

                <JetSecondaryButton class="mt-2 mr-2" type="button" @click.prevent="selectNewPhoto">
                    Select A New Photo
                </JetSecondaryButton>

                <JetSecondaryButton
                    v-if="user.profile_photo_path"
                    type="button"
                    class="mt-2"
                    @click.prevent="deletePhoto"
                >
                    Remove Photo
                </JetSecondaryButton>

                <JetInputError :message="form.errors.photo" class="mt-2" />
            </div>

            <!-- Name -->
            <div class="grid grid-cols-2 gap-8">
                <FormKit type="text" label="Voornaam" v-model="form.first_name" :errors="form.errors.first_name" />
                <FormKit type="text" label="Achternaam" v-model="form.last_name" :errors="form.errors.last_name" />
            </div>
            <FormKit type="text" label="Email" v-model="form.email" :errors="form.errors.email" />
            <FormKit
                type="select"
                label="Taal"
                :options="{
                    nl: 'Nederlands',
                    en: 'English',
                }"
                v-model="form.locale"
                :errors="form.errors.locale"
            />

            <div v-if="$page.props.jetstream.hasEmailVerification && user.email_verified_at === null">
                <p class="mt-2 text-sm">
                    Your email address is unverified.

                    <Link
                        :href="route('verification.send')"
                        method="post"
                        as="button"
                        class="text-gray-500-600 underline hover:text-gray-800"
                        @click.prevent="sendEmailVerification"
                    >
                        Click here to re-send the verification email.
                    </Link>
                </p>

                <div v-show="verificationLinkSent" class="mt-2 text-sm font-medium text-green-500-600">
                    A new verification link has been sent to your email address.
                </div>
            </div>
        </template>

        <template #actions>
            <ActionMessage :on="form.recentlySuccessful" class="mr-3"> Saved. </ActionMessage>

            <Button @click="updateProfileInformation" :form="form"> Add </Button>
        </template>
    </Section>
</template>
